







import Vue from "vue";
import MarkdownParser from "@/components/MarkdownParser.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default Vue.extend({
  components: { MarkdownParser, Breadcrumb },
});
